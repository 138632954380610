import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { getUrlSync, IActionContext } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

/**
 * Syncronously creates a product url
 * @param product Simple product to get url from
 * @param category Category to add to product url
 */
export function getProductUrlSync(product: SimpleProduct, ctx: IActionContext, category?: CategoryHierarchy): string {
    const selectedProduct = { ...product, RecordId: product.MasterProductId || product.RecordId };

    return getProductPageUrlSync(selectedProduct.Name || '', selectedProduct.RecordId, ctx, category)!;
}

/**
 * Syncronously creates a product detail page url
 * @param name Simple product name
 * @param recordId Record id of product
 */
export function getProductPageUrlSync(name: string, recordId: number, ctx: IActionContext, category?: CategoryHierarchy): string {
    return getUrlSync('product', ctx, {
        product: {Name: name || '', RecordId: recordId},
        category
    })!;
}