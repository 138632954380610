import { CacheType, IActionInput } from '@msdyn365-commerce/core';

/**
 * ReviewsGetRatingsSummary Input Action
 */
export class GetRatingsSummaryInput implements IActionInput {
    /**
     * The Id representing the product to get the ratings for.
     */
    public productId: string;

    /**
     * The ID representing the tenant.
     */
    public tenantId: string;

    /**
     * The ratings and reviews service endpoint
     */
    public serviceEndpoint: string;

    constructor(
        productId: string,
        tenantId: string,
        serviceEndpoint: string
    ) {
        this.productId = productId;
        this.tenantId = tenantId;
        this.serviceEndpoint = serviceEndpoint;
    }

    // RnR does not use RS endpoint, so shouldn't use buildCacheKey
    public getCacheKey = () => `${this.productId}-${this.tenantId}`;
    public getCacheObjectType = () => 'RatingsSummary';
    public dataCacheType = (): CacheType => 'application';
}
