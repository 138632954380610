import { IRefineFullProductSearchOutput } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
import { refineSearchByTextAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductDetailsCriteria } from './get-full-products';
import { parseSearchData } from './utilities/input-data-parser';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';
import { getRefinedFullProducts, IRefinableProductsInput } from './utilities/refiner-utils';
import { getProductDetailsCriteriaFromActionInput } from './utilities/utils';

/**
 * Input for refining products returned by the search text.
 */
export class FullProductsRefineSearchByTextInput implements IActionInput, IRefinableProductsInput {
    public readonly searchText: string;
    public readonly channelId?: number;
    public readonly refinementCriteria: ProductRefinerValue[];
    public readonly itemsPerPage?: number;
    public readonly skipCount?: number;
    public readonly catalogId: number;
    public productDetailsCriteria?: ProductDetailsCriteria;
    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;

    constructor(
        queryResultSettingsProxy: QueryResultSettingsProxy,
        searchText?: string,
        channelId?: number,
        refinementCriteria?: ProductRefinerValue[],
        catalogId?: number,
        criteria?: ProductDetailsCriteria
    ) {
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.searchText = searchText || '';
        this.channelId = channelId;
        this.refinementCriteria = refinementCriteria || [];
        this.catalogId = catalogId || 0;
        this.productDetailsCriteria = criteria;
    }

    public getCacheKey = () => `FullProductsRefineSearchByTextInputCache`;
    public getCacheObjectType = () => 'FullProductsRefineSearchByTextInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Creates the input required to make the core action calls
 */
export const createFullProductsRefineSearchByTextInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const refinementCriteria = inputData.config && inputData.config.refinementCriteria;
    const queryResultSettingsProxy = QueryResultSettingsProxy.fromInputData(inputData);
    if (!Array.isArray(refinementCriteria)) {
        return new FullProductsRefineSearchByTextInput(queryResultSettingsProxy);
    }

    const searchInputData = parseSearchData(inputData);
    const catalogId = inputData.requestContext.apiSettings.catalogId;
    const productDetailsCriteria = getProductDetailsCriteriaFromActionInput(inputData);

    return new FullProductsRefineSearchByTextInput(
        queryResultSettingsProxy,
        searchInputData.q,
        searchInputData.channelId,
        <ProductRefinerValue[]>refinementCriteria,
        catalogId,
        productDetailsCriteria
    );
};

/**
 * Calls the refine-search-by-text action.
 * Based on search result calls get-full-products to get all the product details.
 */
export function getFullProductsByRefineSearchTextAction(
    input: FullProductsRefineSearchByTextInput,
    ctx: IActionContext
): Promise<IRefineFullProductSearchOutput> {
    return getRefinedFullProducts(
        input,
        ctx,
        // @ts-ignore: Promise.then typing conflict
        () => {
            return refineSearchByTextAsync(
                { callerContext: ctx, queryResultSettings: input.queryResultSettingsProxy.QueryResultSettings },
                input.channelId || 0,
                input.catalogId,
                input.searchText,
                input.refinementCriteria);
        }
    );
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-full-products-by-refine-search-text',
    action: <IAction<IRefineFullProductSearchOutput>>getFullProductsByRefineSearchTextAction,
    input: createFullProductsRefineSearchByTextInput,
    isBatched: false
});
