import { IAction } from '@msdyn365-commerce/core';
import { createObservableDataAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy/dist';
import { createGetOrgUnitConfigurationInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Get Org Unit Configuration Data Action
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-org-unit-configuration',
    action: <IAction<ProductSearchResult[]>>retailAction,
    input: () => {
        return createGetOrgUnitConfigurationInput();
    }
});
