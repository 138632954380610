import { IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';

export const getRnrPartnerId = (args: ICreateActionContext<IGeneric<IAny>> | IActionContext): string => {
    return  args.requestContext.apiSettings.rnr.id || '';
};

export const getRnrEndpointId = (args: ICreateActionContext<IGeneric<IAny>> | IActionContext): string => {
    return args.requestContext.apiSettings.rnr.url || '';
};

export const getRnrAuthEndpointId = (args: ICreateActionContext<IGeneric<IAny>> | IActionContext): string => {
    // @ts-ignore
    const endpoint = <string>(args.requestContext.apiSettings.rnr.proxyUrl ? args.requestContext.apiSettings.rnr.proxyUrl : args.requestContext.apiSettings.rnr.url || '');
    if (endpoint.length > 0 && endpoint.charAt(endpoint.length-1) === '/') {
        return endpoint.substring(0, endpoint.length-1);
    }
    return endpoint;
};