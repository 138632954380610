import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductRefiner } from '@msdyn365-commerce/retail-proxy';

import { getRefinersByTextAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { parseSearchData } from './utilities/input-data-parser';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';
import { buildCacheKey } from './utilities/utils';

/**
 * Action Input class for the getRefinersByText data action
 */
export class RefinersByTextInput implements IActionInput {
    public readonly catalogId: number;
    public readonly searchText: string;
    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;
    private apiSettings: ICommerceApiSettings;

    constructor(
        searchText: string,
        catalogId: number,
        queryResultSettingsProxy: QueryResultSettingsProxy,
        apiSettings: ICommerceApiSettings) {
        this.catalogId = catalogId;
        this.searchText = searchText;
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`${this.searchText}|${this.catalogId}|${this.queryResultSettingsProxy.cacheKeyHint}`, this.apiSettings);
    public getCacheObjectType = () => 'ProductRefiner';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
export const createRefinersByTextInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const searchInputData = parseSearchData(inputData);
    const catalogId = inputData.config ? Number(inputData.config.catalogId) : 0;

    return new RefinersByTextInput(
        searchInputData.q,
        Number.isNaN(catalogId) ? 0 : catalogId,
        QueryResultSettingsProxy.fromInputData(inputData),
        inputData.requestContext.apiSettings
    );
};

/**
 * Calls the Retail API and returns all refiners by text
 */
export async function getRefinersByTextAction(input: RefinersByTextInput, ctx: IActionContext): Promise<ProductRefiner[]> {
    return getRefinersByTextAsync(
        { callerContext: ctx, queryResultSettings: input.queryResultSettingsProxy.QueryResultSettings },
        input.catalogId,
        input.searchText);
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-refiners-by-text',
    action: <IAction<ProductRefiner[]>>getRefinersByTextAction,
    input: createRefinersByTextInput
});
