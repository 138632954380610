import { FullProduct } from '@msdyn365-commerce/commerce-entities';
import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICreateActionContext } from '@msdyn365-commerce/core';
import { CommerceListLine } from '@msdyn365-commerce/retail-proxy';

import { getByCustomerAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CommerceListsDataActions.g';
import { FullProductInput, getFullProducts, getProductDetailsCriteriaFromActionInput } from './index';

/**
 * Input class for getActiveWishlistItems data action
 */
export class ActiveWishlistInput implements IActionInput {
    public getCacheKey = () => `ActiveWishlistItems`;
    public getCacheObjectType = () => 'ActiveWishlistItems';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext) => {
    return new ActiveWishlistInput();
};

/**
 * Calls the Retail API and returns a CommerceList object based on the passed GetCartInput
 */
export async function getActiveWishlistItems(input: ActiveWishlistInput, ctx: IActionContext): Promise<FullProduct[]> {
    // If no cart ID is provided in input, we need to create a cart object
    if (!input) {
        throw new Error('[getActiveWishlistItems]No valid Input was provided, failing');
    }
    const accountNumber = ctx.requestContext.user.isAuthenticated && ctx.requestContext.user.customerAccountNumber;

    if(accountNumber) {
        const wishlists = await getByCustomerAsync({ callerContext: ctx, queryResultSettings: {} }, accountNumber);

        if(wishlists && wishlists.length > 0) {
            const productInputs: FullProductInput[] = [];
            if(wishlists[0].CommerceListLines) {
                wishlists[0].CommerceListLines.forEach((commerceListLine: CommerceListLine) => {
                    if(commerceListLine.ProductId) {
                        productInputs.push(new FullProductInput(commerceListLine.ProductId, ctx.requestContext.apiSettings, getProductDetailsCriteriaFromActionInput(ctx)));
                    }
                });
            }

            if(productInputs.length > 0) {
                return getFullProducts(productInputs, ctx)
                    .then(products => {
                        if (products) {
                            return products;
                        } else {
                            return <FullProduct[]>[];
                        }
                    })
                    .catch(error => {
                        ctx.trace(error);
                        ctx.telemetry.exception(error);
                        ctx.telemetry.debug(`[getActiveWishlistItems]Unable to hydrate cart with product information`);
                        throw new Error('[getActiveWishlistItems]Unable to hydrate cart with product information');
                    });
            } else {
                ctx.trace('No productIds found for wishlist');
            }
        } else {
            ctx.trace('[getActiveWishlistItems]Not able to get wishlists for the customer');
            return <FullProduct[]>[];
        }
    }
    ctx.trace('[getActiveWishlistItems]User token not found for wishlists');
    return <FullProduct[]>[];
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-items-in-wishlist',
    action: <IAction<FullProduct[]>>getActiveWishlistItems,
    input: createInput
});
