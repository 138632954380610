import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import {
    createObservableDataAction,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { ProductRefinerValue, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';

import { createRefineSearchByCategoryInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { retailAction } from '@msdyn365-commerce/retail-proxy/dist/retail-action';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';
import { buildCacheKey } from './utilities/utils';

/**
 * Action Input class for the refineSearchByCategory data action
 */
export class RefineSearchByCriteriaInput implements IActionInput {
    public readonly categoryId?: number;
    public readonly channelId?: number;
    public readonly refinementCriteria: ProductRefinerValue[];
    public readonly catalogId: number;
    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;
    private apiSettings: ICommerceApiSettings;

    constructor(
        queryResultSettingsProxy: QueryResultSettingsProxy,
        apiSettings: ICommerceApiSettings,
        categoryId?: number,
        channelId?: number,
        refinementCriteria?: ProductRefinerValue[],
        catalogId?: number
    ) {
        this.categoryId = categoryId;
        this.channelId = channelId;
        this.refinementCriteria = refinementCriteria || [];
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.catalogId = catalogId || 0;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`RefineSearchByCriteriaInputCache`, this.apiSettings);
    public getCacheObjectType = () => 'RefineSearchByCriteriaInput';
    public dataCacheType = (): CacheType => 'request';
}

/**
 * createInput method for the refineSearchByCategory data action
 */
export const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const refinementCriteria = inputData.config && inputData.config.refinementCriteria;

    if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.categoryId) {
        return createRefineSearchByCategoryInput(
            QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings,
            +inputData.requestContext.apiSettings.channelId,
            inputData.config ? Number(inputData.config.catalogId) : 0,
            +inputData.requestContext.query.categoryId,
            !Array.isArray(refinementCriteria) ? [] : refinementCriteria.map((refinementCriterion: ProductRefinerValue) => refinementCriterion)
        );
    }

    throw new Error('Please specify categoryId, refinerId, and refinerSourceValue query string in request.');
};

/**
 * The RefineSearchByCategory data action
 * Uses categoryId query string parameter to call the RefinerSearchByCategory
 * RetailServer API to get information about products
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/regin-search-by-category',
    action: <IAction<ProductSearchResult[]>>retailAction,
    input: createInput
});
