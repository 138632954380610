/*
* @name - unique
* @description - Remove duplicate elements in the array.
* @export
* @public
* @param {array} value - The array to examine.
* @returns {array} - An array with only unique elements.
*/
export function unique<T>(value: T[]): T[] {
    return Array.from(new Set(value));
}