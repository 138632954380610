import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric, IRequestContext } from '@msdyn365-commerce/core';
import { getCategoriesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CategoriesDataActions.g';

/**
 * Input for get-categories data action
 */
export class CategoriesInput implements IActionInput {
    public readonly maxItems: number;
    public readonly channelId: number;
    private _mappedToHierarchy: boolean;
    constructor(context: IRequestContext, mappedToHierarchy: boolean, maxItems?: number) {
        this._mappedToHierarchy = mappedToHierarchy;
        this.maxItems = maxItems || 250;
        this.channelId = context && context.apiSettings && context.apiSettings.channelId ? Number(context.apiSettings.channelId) : 0;
    }

    public getCacheKey = () => `${this.channelId}|top-${this.maxItems || 250}`;
    public getCacheObjectType = () => (this._mappedToHierarchy ? 'CategoryHierarchy' : 'Category');
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Creates the input required to make the retail api call
 */
export const createCategoriesInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const topItems = inputData.config && inputData.config.topCategories && parseInt(inputData.config.topCategories, 10);
    return new CategoriesInput(inputData.requestContext, false, topItems);
};

/**
 * Calls the Retail API and returns all the categories as a flat list
 */
export async function getCategoryAction(input: CategoriesInput, ctx: IActionContext): Promise<CategoryHierarchy[]> {
    return <CategoryHierarchy[]><unknown>(getCategoriesAsync({ callerContext: ctx }, input.channelId));
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-categories',
    action: <IAction<CategoryHierarchy[]>>getCategoryAction,
    input: createCategoriesInput
});
