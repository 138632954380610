import { IRefineFullProductSearchOutput } from '@msdyn365-commerce/commerce-entities';
import { IActionContext } from '@msdyn365-commerce/core';
import { ProductRefinerValue, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import getFullProducts, { FullProductInput, ProductDetailsCriteria } from '../get-full-products';

/**
 * Common refinable products input includes both product detail criteria and refinement criteria.
 */
export interface IRefinableProductsInput {
    refinementCriteria: ProductRefinerValue[];
    productDetailsCriteria?: ProductDetailsCriteria;
}

/**
 * If refinement criteria is set, then gets refined products.
 * @param input refinable products input
 * @param ctx action context
 * @param getRefinedProducts callback function to get a promise (e.g. refineSearchByCategoryAsync, refineSearchByTextAsync)
 * that is executed to get refined products or full set of products.
 */
export async function getRefinedFullProducts(
    input: IRefinableProductsInput,
    ctx: IActionContext,
    getRefinedProducts: () => Promise<ProductSearchResult[]>
): Promise<IRefineFullProductSearchOutput> {

    if (!input || !input.refinementCriteria) {
        ctx.trace('[getFullProductsByRefineSearchCategoryAction] Invalid input.');
        return <IRefineFullProductSearchOutput>{};
    }

    if (!input.refinementCriteria.length) {
        ctx.trace('[getFullProductsByRefineSearchCategoryAction] No refiners specified.');
        return <IRefineFullProductSearchOutput>{};
    }
    const { apiSettings } = ctx.requestContext;

    let fullProductInputs: FullProductInput[] = [];

    const searchResults = await getRefinedProducts();
    if (!searchResults) {
        ctx.telemetry.error('[getFullProductsByRefineSearchCategoryAction] no searchResults returned');
    } else {
        fullProductInputs = searchResults.map(
            (product: ProductSearchResult): FullProductInput => {
                return new FullProductInput(product.RecordId, apiSettings, input.productDetailsCriteria || new ProductDetailsCriteria());
            }
        );
    }

    if (fullProductInputs.length) {
        const productResult = await getFullProducts(fullProductInputs, ctx);
        return {
            productSearchResult: productResult,
            refinementCriteria: input.refinementCriteria
        };
    } else {
        return {
            productSearchResult: [],
            refinementCriteria: input.refinementCriteria
        };
    }
}