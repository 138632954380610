import { IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductRefiner } from '@msdyn365-commerce/retail-proxy';
import getCurrentCategory, { CurrentCategoryInput } from './get-current-category';

import { getRefinersByCategoryAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';

/**
 * Action Input class for the getRefinersByCategory data action
 */
export class RefinersByCategoryInput implements IActionInput {
    public readonly catalogId: number;
    public readonly currentCategory: CurrentCategoryInput;
    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;
    constructor(category: CurrentCategoryInput, queryResultSettingsProxy: QueryResultSettingsProxy, catalogId?: number) {
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.catalogId = catalogId || 0;
        this.currentCategory = category;
    }

    public getCacheKey = () => `${this.currentCategory.getCacheKey()}|${this.catalogId}|${this.queryResultSettingsProxy.cacheKeyHint}`;
    public getCacheObjectType = () => 'ProductRefiner';
    public shouldCacheOutput = () => true;
}

/**
 * Creates the input required to make the retail api call
 */
export const createRefinersByCategoryInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    if (inputData && inputData.requestContext) {
        const catalogId = inputData.config ? Number(inputData.config.catalogId) : 0;
        const currentCategory = new CurrentCategoryInput(inputData.requestContext);

        return new RefinersByCategoryInput(
            currentCategory,
            QueryResultSettingsProxy.fromInputData(inputData),
            Number.isNaN(catalogId) ? 0 : catalogId
        );
    }

    throw new Error('Please specify categoryId query string in request.');
};

/**
 * Calls the Retail API and returns all refiners by category
 */
export async function getRefinersByCategoryAction(input: RefinersByCategoryInput, ctx: IActionContext): Promise<ProductRefiner[]> {
    let categoryId = input.currentCategory.categoryId;
    if (input.currentCategory.categorySlug && !categoryId) {
        const category = await getCurrentCategory(input.currentCategory, ctx);
        if (!category) {
            ctx.trace('[getRefinersByCategoryAction] Unable to find category');
            return <ProductRefiner[]>[];
        }
        categoryId = category.RecordId;
    }

    return getRefinersByCategoryAsync(
        { callerContext: ctx, queryResultSettings: input.queryResultSettingsProxy.QueryResultSettings },
        input.catalogId,
        categoryId || 0
    );
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-refiners-by-category',
    action: <IAction<ProductRefiner[]>>getRefinersByCategoryAction,
    input: createRefinersByCategoryInput
});
