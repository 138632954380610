import { buildCacheKey, getProductUrlSync, getSelectedProductIdFromActionInput, getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { CacheType, createObservableDataAction, IAction, IActionContext,IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { IDefaultPageSummaryConfig } from '../default-page-summary/default-page-summary.props.autogenerated';
import { IPageSummaryData } from '../IPageSummaryData';

/** Product Page Summary Input */
export class ProductPageSummaryInput implements IActionInput {
    public apiSettings: ICommerceApiSettings;
    public config: IDefaultPageSummaryConfig;

    constructor(config: IDefaultPageSummaryConfig, apiSettings: ICommerceApiSettings) {
        this.config = config || {};
        this.apiSettings = apiSettings;
    }

    public getCacheObjectType = (): string => 'ProductPageSummary';
    public getCacheKey = (): string => `${buildCacheKey('ProductPageSummary', this.apiSettings)}-${this.config.title}`;
    public dataCacheType = (): CacheType => 'request';
}

const createGetSelectedVariantInput = (inputData: IActionContext): SelectedVariantInput => {
    const productId = getSelectedProductIdFromActionInput(inputData);

    if (productId) {
        return new SelectedVariantInput(+productId, +inputData.requestContext.apiSettings.channelId, []);
    } else {
        throw new Error('Unable to create SelectedVariantInput, no productId found on module config or query');
    }
};

const action = async (input: ProductPageSummaryInput, context: IActionContext): Promise<IPageSummaryData> => {
    const { config } = input;
    let simpleProductData;
    try {
        simpleProductData = await getSelectedVariant(createGetSelectedVariantInput(context), context);
    } catch (e) {
        // Do nothing, if there's an error we fall back to values defined from data
    }
    if (simpleProductData) {
        let productUrl: string | undefined;
        try {
            productUrl = getProductUrlSync(simpleProductData, context);
            // @ts-ignore - TODO: property exits in new version of SDK. Remove once released.
            const canonicalDomain = context.requestContext.canonicalDomain;
            if (productUrl && canonicalDomain) {
                productUrl = `https://${canonicalDomain}${productUrl}`;
            } else {
                productUrl = undefined;
            }
        } catch (e) {
            productUrl = undefined;
        }
        return {
            title: simpleProductData.Name,
            description: simpleProductData.Description,
            sharingImageUrl: simpleProductData.PrimaryImageUrl,
            canonicalUrl: productUrl,
            faviconUrl: config && config.faviconUrl
        };
    // If the action fails fallback to values defined from data
    } else if (config) {
        return {
            title: config.title,
            description: config.description,
            sharingImageUrl: config.sharingImage && config.sharingImage.src,
            faviconUrl: config && config.faviconUrl
        };
    } else {
        return {};
    }
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/page-summary/product-page-summary',
    action: <IAction<IPageSummaryData>>action,
    input: (args: ICreateActionContext) => {
        return new ProductPageSummaryInput(<IDefaultPageSummaryConfig>args.config, args.requestContext.apiSettings);
    }
});