import { IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext } from '@msdyn365-commerce/core';
import { Address, Customer } from '@msdyn365-commerce/retail-proxy';
import { createAddressManagementInput, doAddressManagementOperation, IAddressManagementInput } from './add-address';

export function deleteAddressHandler(customer: Customer, address: Address): Customer {
    customer.Addresses = customer.Addresses || [];
    customer.Addresses = [...customer.Addresses.filter(cur => cur.RecordId !== address.RecordId)];
    return customer;
}

export async function deleteAddressAction(input: IAddressManagementInput, ctx: IActionContext): Promise<Address[]> {
    return doAddressManagementOperation(input, ctx, deleteAddressHandler);
}

/**
 * The deleteAddress data action
 * Calls the read RetailServer API to get information about a customer
 * Removes all address information from the retireved customer, and
 * then updates that customer via the update RetailServer API.
 */
export default createObservableDataAction<Address[]>({
    id: '@msdyn365-commerce-modules/retail-actions/delete-address',
    action: <IAction<Address[]>>deleteAddressAction,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createAddressManagementInput),
    isBatched: false
});
