import { IAction } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IDataServiceRequest, retailAction } from '@msdyn365-commerce/retail-proxy/dist';
import { createSearchOrgUnitLocationsInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { buildQueryResultSettings } from './utilities/utils';

/**
 * Search Org Unit Locations action createInput method
 */
export function createSearchOrgUnitLocationsInputFunc(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): IDataServiceRequest {
    return createSearchOrgUnitLocationsInput(buildQueryResultSettings(inputData), {});
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/search-org-unit-locations',
    action: <IAction<ProductSearchResult[]>>retailAction,
    input: createSearchOrgUnitLocationsInputFunc
});
