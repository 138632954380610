import { CacheType, IActionInput } from '@msdyn365-commerce/core';

export type OrderBy =
    | 'None'
    | 'MostRecent'
    | 'Oldest'
    | 'HighestRating'
    | 'LowestRating'
    | 'MostHelpful'
    | 'LeastHelpful'
    | 'MostAsked'
    | 'MostAnswered'
    | 'LeastConcerns'
    | 'MostConcerns'
    | 'ReviewerNameAsc'
    | 'ReviewerNameDesc';

/**
 * ReviewsGetRatingsSummary Input Action
 */
export class GetProductReviewsInput implements IActionInput {
    /**
     * The Id representing the product to get the reviews for.
     */
    public productId: string;

    /**
     * The ID representing the tenant.
     */
    public tenantId: string;

    /**
     * The market for which to get the reviews from.
     */
    public localeListFilter: string;

    /**
     * Number of reviews to be shown on each page.
     */
    public pageSize: number;

    /**
     * Order in which reviews to be shown.
     */
    public orderBy: string;

    /**
     * Need to set this value if reviews need to be filtered with specific star rating .
     */
    public starFilters?: string;

    /**
     * Need to set this value if we need to skip few reviews.
     */
    public skipItems: number;

    /**
     * The continuation token
     */
    public pageToken: string | null;

    /**
     * Selects which data sources to use to retrieve data
     */
    public dataSources: string | null;

    /**
     * The ratings and reviews service endpoint
     */
    public serviceEndpoint: string;

    constructor(
        productId: string,
        tenantId: string,
        localeListFilter: string,
        serviceEndpoint: string,
        orderBy: string = 'MostHelpful',
        starFilters: string = '',
        skipItems: number = 0,
        pageSize: number = 10,
        pageToken: string | null = null,
        dataSources: string | null = null
    ) {
        this.productId = productId;
        this.tenantId = tenantId;
        this.localeListFilter = localeListFilter;
        // Max page size which Rnr API supports is 25.
        // Hence if config has page size more than 25, we'll default it to 25.
        this.pageSize = pageSize > 25 ? 25 : pageSize;
        this.orderBy = orderBy;
        this.starFilters = starFilters;
        this.skipItems = skipItems;
        this.pageToken = pageToken;
        this.dataSources = dataSources;
        this.serviceEndpoint = serviceEndpoint;
    }

    public getCacheKey = () => `${this.productId}-${this.tenantId}-${this.localeListFilter}-${this.pageSize}-${this.orderBy}-${this.starFilters}-${this.skipItems}`;
    public getCacheObjectType = () => 'ProductReview';
    public dataCacheType = (): CacheType => 'request';
}
