import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICreateActionContext } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

import { getSimpleProducts, ProductInput } from './index';

/**
 * Input class for activeCartWithProducts data action
 */
export class ActiveCartProductsInput implements IActionInput {
    public getCacheKey = () => `ActiveCartProducts`;
    public getCacheObjectType = () => 'ActiveCartProducts';
    public dataCacheType = (): CacheType => 'none';
}

const createInput = (inputData: ICreateActionContext) => {
    return new ActiveCartProductsInput();
};

/**
 * Calls the Retail API and returns a cart object based on the passed GetCartInput
 */
export async function getActiveCartProductsAction(input: ActiveCartProductsInput, ctx: IActionContext): Promise<SimpleProduct[]> {
    // If no cart ID is provided in input, we need to create a cart object
    if (!input) {
        throw new Error('[getActiveCartWithProducts]No valid Input was provided, failing');
    }

    const cartState = await getCartState(ctx);
    const cart = cartState.cart;

    // If there are cart lines, make call to get products
    if (cart && cart.CartLines && cart.CartLines.length) {
        ctx.trace('Getting cart product information...');
        return getSimpleProducts(
            <ProductInput[]>cart.CartLines.map(cartLine => {
                if (cartLine.ProductId) {
                    return new ProductInput(cartLine.ProductId, ctx.requestContext.apiSettings);
                }
                return undefined;
            }).filter(Boolean),
            ctx
        )
            .then((products: SimpleProduct[]) => {
                if (products) {
                    return products;
                } else {
                    return [];
                }
            })
            .catch((error: Error) => {
                ctx.trace(error.toString());
                ctx.telemetry.error(error.message);
                ctx.telemetry.debug(`[getActiveCartWithProducts]Unable to hydrate cart with product information`);
                throw new Error('[getActiveCartWithProducts]Unable to hydrate cart with product information');
            });
    }

    ctx.trace('[getActiveCartWithProducts]No Products Found in cart');
    return <SimpleProduct[]>[];
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-products-in-active-cart',
    action: <IAction<SimpleProduct[]>>getActiveCartProductsAction,
    input: createInput
});
