import { Image, ISubList, List } from '@msdyn365-commerce-modules/data-types';
import { IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import GetProducts, { ProductInput } from './get-simple-products';

/**
 * List Input action
 */
export class ListInput implements IActionInput {
    public DocumentId: string;
    public Title: string;
    public Description: string;
    public ShortDescription: string;
    public BackgroundColor: string;
    public ForegroundColor: string;
    public Images: Image[];
    public Items: (IProductItem[] | ISubList)[];
    public ProductItems: IProductItem[];
    public ItemsCount: number;
    public ItemsPerPage: number;
    public SkipCount: number;

    constructor(
        documentId: string,
        title: string,
        description: string,
        shortDescription: string,
        backgroundColor: string,
        foregroundColor: string,
        images: Image[],
        items: (IProductItem[] | ISubList)[],
        productItems: IProductItem[],
        itemsCount: number,
        itemsPerPage: number,
        skipCount: number
    ) {
        this.DocumentId = documentId;
        this.Title = title;
        this.Description = description;
        this.ShortDescription = shortDescription;
        this.BackgroundColor = backgroundColor;
        this.ForegroundColor = foregroundColor;
        this.Images = images;
        this.Items = items;
        this.ProductItems = productItems;
        this.ItemsCount = itemsCount;
        this.ItemsPerPage = itemsPerPage;
        this.SkipCount = skipCount;
    }

    public shouldCacheOutput = () => true;
    public getCacheObjectType = () => 'LIST';
    public getCacheKey = () => `${this.DocumentId}-${this.ItemsCount}-${this.ItemsPerPage}-${this.SkipCount}`;
}

export interface IProductItem {
    RecordId: string;
    CatalogId: string;
}

/**
 * List item type enum
 */
const enum ListItemType {
    list = 'list',
    product = 'product'
}

/**
 * Calls to getSimpleProducts to get product array by ID's in list.
 */
export async function getListData(input: ListInput, ctx: IActionContext): Promise<List> {
    ctx.trace(`List Title: ${input.Title}`);

    // @ts-ignore
    const { apiSettings } = ctx.requestContext;

    const sublists: (SimpleProduct | ISubList)[] = [];
    const result: List = {
        Title: input.Title,
        Description: input.Description,
        ShortDescription: input.ShortDescription,
        BackgroundColor: input.BackgroundColor,
        ForegroundColor: input.ForegroundColor,
        Items: sublists,
        ItemsCount: input.ItemsCount
    };

    if (input && input.Items && input.Items.length) {
        // get all products
        let products: SimpleProduct[] = [];
        if (input.ProductItems && input.ProductItems.length) {
            const inputArray = input.ProductItems.map(item => new ProductInput(+item.RecordId, apiSettings));
            products = <SimpleProduct[]>await GetProducts(inputArray, ctx);
        }

        // build map from recordId to product, which will be used later when build the output.
        const productMap: { [recordId: string]: SimpleProduct } = {};
        products.forEach(item => {
            if (item) {
                productMap[`${item.RecordId}`] = item;
            }
        });

        for (const listitem of input.Items) {
            if (isSublist(listitem)) {
                result.Items.push(listitem);
            } else {
                listitem.forEach(item => {
                    if (productMap[item.RecordId]) {
                        result.Items.push(productMap[item.RecordId]);
                    }
                });
            }
        }
    }

    return result;
}

/**
 * Check if an item is sublist.
 */
function isSublist(listItem: IProductItem[] | ISubList): listItem is ISubList {
    return (<ISubList>listItem).Images !== undefined;
}

/**
 * Get images.
 */
function getImages(images: IGeneric<IAny>[]): Image[] {
    const resultImageList: Image[] = [];

    if (!images) {
        return resultImageList;
    }

    images.forEach(item => {
        if (item && item.image && item.image.href) {
            // add image item
            const imageItem: Image = {
                href: item.image.href,
                altText: item.image.altText,
                title: item.image.title,
                width: item.image.width,
                height: item.image.height
            };

            resultImageList.push(imageItem);
        }
    });

    return resultImageList;
}

/**
 * Get the url of sub list.
 */
function getSubListUrl(listName: string, sitePath: string): string {
    if (!listName) {
        return '';
    }

    if(!sitePath) {
        return `/${listName}.l`;
    }

    // sitePath has a leading '/'
    return `${sitePath}/${listName}.l`;
}

/**
 * Get input list data.
 */
function getInutListData(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): IGeneric<IAny> {
    let listData =
        !inputData || !inputData.requestContext || !inputData.requestContext.pageData ? null : inputData.requestContext.pageData.list;
    if (
        !inputData ||
        !inputData.requestContext ||
        !inputData.requestContext.pageData ||
        !inputData.requestContext.pageData.list ||
        !inputData.requestContext.pageData.list.content ||
        !inputData.requestContext.pageData.list.content.items ||
        !inputData.requestContext.pageData.list.content.title ||
        !inputData.requestContext.pageData.list._id
    ) {
        if (
            !inputData ||
            !inputData.data ||
            !inputData.data.list ||
            !inputData.data.list.content ||
            !inputData.data.list.content.items ||
            !inputData.data.list.content.title ||
            !inputData.data.list._id
        ) {
            console.error('data is not well defined for list action input');
            throw new Error('data is not well defined for list action input');
        }

        listData = inputData.data.list;
    }
    return listData;
}

/**
 * Get sub list item.
 */
function getSubListItem(item: IGeneric<IAny>, sitePathOfRequest: string): ISubList {
    const imageList: Image[] = getImages(item.fields.content.images);

    const sublistHref = getSubListUrl(item.fields._name, sitePathOfRequest);
    return {
        Title: item.fields.content.title,
        Description: item.fields.content.description,
        ShortDescription: item.fields.content.shortDescription,
        BackgroundColor: item.fields.content.backgroundColor,
        ForegroundColor: item.fields.content.foregroundColor,
        Href: sublistHref,
        Images: imageList
    };
}

/**
 * Get site path
 */
function getSitePath(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): string {
    // @ts-ignore
    return inputData && inputData.requestContext && inputData.requestContext.sitePath ? inputData.requestContext.sitePath : '';
}

/**
 * Get items per page
 */
function getItemsPerPage(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): number {
    if (!inputData || !inputData.config || !inputData.config.itemsPerPage) {
        return 50;
    }

    const result = Number(inputData.config.itemsPerPage);
    if (isNaN(result)) {
        return 50;
    }

    return result;
}

/**
 * Get skip count
 */
function getSkipCount(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): number {
    return inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.skipCount
        ? Number(inputData.requestContext.query.skipCount)
        : 0;
}

/**
 * Creates the input required to make the list call to get products.
 */
const createListInput = (inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): IActionInput => {
    const listData: IGeneric<IAny> = getInutListData(inputData);
    const listItems: (IProductItem[] | ISubList)[] = [];
    let productList: IProductItem[] = [];

    const parentImageList: Image[] = getImages(listData.content.images);

    const itemsPerPage = getItemsPerPage(inputData);
    const skipCount = getSkipCount(inputData);

    // This is the list contains all product and will be used to call getSimpleProducts data action.
    const productItems: IProductItem[] = [];
    const sum = skipCount + itemsPerPage;
    for (let index = skipCount; index < listData.content.items.length && index < sum; index++) {
        const item = listData.content.items[index];
        if (item.type === ListItemType.list) {
            if (!item.fields || !item.fields.content || !item.fields.content.title) {
                console.error(`sublist item fields, content or title missing in list ${listData._id}`);
                continue;
            }

            // if any elements in product list, then copy it and add to list items
            if (!(productList.length === 0)) {
                const clonedList = [...productList];
                listItems.push(clonedList);
                productList = [];
            }

            // build and add sublist item
            listItems.push(getSubListItem(item, getSitePath(inputData)));
        }

        if (item.type === ListItemType.product) {
            if (!item.fields || !item.fields.recordId) {
                console.error(`product item missing recordId in list ${listData._id}`);
                continue;
            }

            // add product item
            const productItem: IProductItem = {
                RecordId: item.fields.recordId,
                CatalogId: item.fields.catalogId || '0'
            };

            productList.push(productItem);
            productItems.push(productItem);
        }
    }

    // save the last portion of product items.
    if (!(productList.length === 0)) {
        listItems.push(productList);
    }

    return new ListInput(
        listData._id,
        listData.content.title,
        listData.content.description,
        listData.content.shortDescription,
        listData.content.backgroundColor,
        listData.content.foregroundColor,
        parentImageList,
        listItems,
        productItems,
        listData.content.items.length,
        itemsPerPage,
        skipCount
    );
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-list',
    action: <IAction<List>>getListData,
    input: createListInput
});
