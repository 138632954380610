import { CacheType, IAction, IActionInput, ICommerceApiSettings } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductSearchResult, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import getCurrentCategory, { CurrentCategoryInput } from './get-current-category';
import getProducts, { ProductInput } from './get-simple-products';

import { searchByCategoryAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';
import { buildCacheKey } from './utilities/utils';

/**
 * Product by category ID Input action
 */
export class ProductsByCategoryInput implements IActionInput {
    public categoryId?: number;
    public categorySlug?: string;
    public categoryName?: string;
    public catalogId: number;
    public currentCategory: CurrentCategoryInput;
    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;
    private apiSettings: ICommerceApiSettings;

    constructor(category: CurrentCategoryInput, apiSettings: ICommerceApiSettings, queryResultSettingsProxy: QueryResultSettingsProxy) {
        this.apiSettings = apiSettings;
        this.currentCategory = category;
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.catalogId = apiSettings.catalogId;
        this.categoryId = category.categoryId;
        this.categorySlug = category.categorySlug;
    }

    public getCacheKey = () => buildCacheKey(`${this.categoryId || this.categorySlug}|${this.catalogId}|${this.queryResultSettingsProxy.cacheKeyHint}`, this.apiSettings);
    public getCacheObjectType = () => 'Products-From-Search';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * createInput method for the getProductsByCategory data aciton
 */
export const createGetProductsByCategoryInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    if (inputData && inputData.requestContext) {
        const currentCategory = new CurrentCategoryInput(inputData.requestContext);
        const queryResultSettingsProxy = QueryResultSettingsProxy.fromInputData(inputData);
        return new ProductsByCategoryInput(
            currentCategory,
            inputData.requestContext.apiSettings,
            queryResultSettingsProxy
        );
    }

    throw new Error('Please specify categoryId query string in request.');
};

/**
 * Action method for the getProductsByCategory data action
 */
export async function getProductsByCategoryAction(input: ProductsByCategoryInput, ctx: IActionContext): Promise<SimpleProduct[]> {
    const { apiSettings } = ctx.requestContext;
    let categoryId = input.currentCategory.categoryId;
    if (input.currentCategory.categorySlug && !categoryId) {
        const category = await getCurrentCategory(input.currentCategory, ctx);
        if (!category) {
            ctx.trace('[getProductsByCategoryAction] Unable to find category');
            return <SimpleProduct[]>[];
        }
        categoryId = category.RecordId;
    }

    const productInputs = await searchByCategoryAsync(
        { callerContext: ctx, queryResultSettings: input.queryResultSettingsProxy.QueryResultSettings },
        +apiSettings.channelId,
        input.catalogId,
        categoryId || 0
    ).then(productSearchResults => {
        return productSearchResults.map(
            (product: ProductSearchResult): ProductInput => {
                return new ProductInput(product.RecordId, apiSettings);
            }
        );
    });

    if (productInputs.length > 0) {
        return <SimpleProduct[]>await getProducts(productInputs, ctx);
    } else {
        return <SimpleProduct[]>[];
    }
}

/**
 * The getProductsByCategory data action
 * Retrieves the current category of the request via the getCurrentCategory data action
 * Then calls the searchByCategory RetailServer API to get a list of products associated with
 * the current category, and finally fully hydrates the data for those prodcuts via the
 * getProducts data action, returning a list of SimpleProducts within the current category.
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-products-by-category',
    action: <IAction<SimpleProduct[]>>getProductsByCategoryAction,
    input: createGetProductsByCategoryInput
});
