import { CacheType, IActionInput } from '@msdyn365-commerce/core';

/**
 * These are the get user review API inputs
 */
export class GetUserReviewInput implements IActionInput {
    /**
     * The Id representing the product to get the ratings for.
     */
    public productId: string;

    /**
     * The ID representing the tenant.
     */
    public tenantId: string;

    /**
     * The user's authorization token
     */
    public authorization: string;

    /**
     * The ratings and reviews service endpoint
     */
    public serviceEndpoint: string;

    constructor(
        productId: string,
        tenantId: string,
        authorization: string,
        serviceEndpoint: string
    ) {
            this.productId = productId;
            this.tenantId = tenantId;
            this.authorization = authorization;
            this.serviceEndpoint = serviceEndpoint;
        }

    public getCacheKey = () => 'GetUserReview';
    public getCacheObjectType = () => 'GetUserReview';
    public dataCacheType = (): CacheType => 'none';
}
