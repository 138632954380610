import {
    CacheType, createObservableDataAction, IAction, IActionContext, IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { Customer } from '@msdyn365-commerce/retail-proxy';
import {
    createReadAsync, readAsync, updateAsync
} from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';

import { buildCacheKey } from './index';

export interface IUpdateCustomerPersonalizationInput extends IActionInput {
    isOptOut: boolean;
    userAccountNumber: string;
    apiSettings: ICommerceApiSettings;
    preferenceType?: string;
}

/**
 *  Input class for the updateCustomerPersonalization
 */
export class updateCustomerPersonalizationInput implements IUpdateCustomerPersonalizationInput {
    public userAccountNumber: string;
    public isOptOut: boolean;
    public apiSettings: ICommerceApiSettings;
    public preferenceType: string | undefined;

    constructor(userAccountNumber: string, isOptOut: boolean, apiSettings: ICommerceApiSettings, preferenceType?: string) {
        this.userAccountNumber = userAccountNumber;
        this.apiSettings = apiSettings;
        this.isOptOut = isOptOut;
        this.preferenceType = preferenceType;
    }

    public getCacheKey = (): string => buildCacheKey(`${this.userAccountNumber}`, this.apiSettings);
    public getCacheObjectType = () => 'Customer';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * The action method for the updateCustomerPersonalization data action
 * @param input The action input
 * @param ctx The action context
 */
export async function updateCustomerPersonalizationAction(input: IUpdateCustomerPersonalizationInput, ctx: IActionContext): Promise<Customer> {
    const { userAccountNumber, isOptOut, preferenceType } = input;

    const customer = await readAsync({ callerContext: ctx }, userAccountNumber);

    if (!customer) {
        throw new Error('Unable to find customer');
    }

    const newCustomer = { ...customer };

    switch (preferenceType) {
        case 'web-tracking':
            newCustomer.OptOutWebActivityTracking = isOptOut;
            break;
        case undefined:
        case 'personalization':
        default:
            newCustomer.OptOutPersonalization = isOptOut;
    }

    const updatedCustomer = await updateAsync({ callerContext: ctx }, newCustomer);

    if (!updatedCustomer) {
        throw new Error('Unable to update customer');
    }

    ctx.update(createReadAsync(updatedCustomer.AccountNumber), updatedCustomer);

    return updatedCustomer;
}

/**
 * The updateCustomerPersonalization data action
 * Returns the updated customer
 */
export default createObservableDataAction<Customer>({
    id: '@msdyn365-commerce-modules/retail-actions/update-customer-personalization',
    action: <IAction<Customer>>updateCustomerPersonalizationAction
});
