import { IAction, IActionContext, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext } from '@msdyn365-commerce/core';
import { Address, Customer } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { createAddressManagementInput, doAddressManagementOperation, IAddressManagementInput } from './add-address';

export function updatePrimaryAddressHandler(customer: Customer, address: Address): Customer {
    customer.Addresses = setPrimaryAddress({ ...address }, [...(customer.Addresses || [])]);
    return customer;
}

export async function updatePrimaryAddressAction(input: IAddressManagementInput, ctx: IActionContext): Promise<Address[]> {
    return doAddressManagementOperation(input, ctx, updatePrimaryAddressHandler);
}

/**
 * The updatePrimaryAddress data action
 * Calls the read RetailServer API to get information about a customer
 * Merge the passed address information with the address information from
 * the retireved customer, and then updates that customer via the update RetailServer API.
 */
export default createObservableDataAction<Address[]>({
    id: '@msdyn365-commerce-modules/retail-actions/update-primary-address',
    action: <IAction<Address[]>>updatePrimaryAddressAction,
    input: <(args: ICreateActionContext) => IActionInput>(<IAny>createAddressManagementInput),
    isBatched: false
});

const setPrimaryAddress = (primaryAddresses: Address, addresses: Address[]): Address[] => {
    return addresses.map(addr => {
        if (addr.RecordId === primaryAddresses.RecordId) {
            addr.IsPrimary = true;
        } else {
            addr.IsPrimary = false;
        }
        return addr;
    });
};
