import { CacheType, IActionInput } from '@msdyn365-commerce/core';
import { IActionContext } from '@msdyn365-commerce/core';

/**
 * Generic input type for use with generic data actions
 */
export class GenericInput<T = {}> implements IActionInput {
    public result: T;
    public cacheKey: string;
    public cacheObjectType: string;

    constructor(cacheKey: string, result: T, cacheObjectType: string) {
        this.cacheKey = cacheKey;
        this.cacheObjectType = cacheObjectType;
        this.result = result;
    }

    public getCacheKey = () => `Generic-${this.cacheKey}`;
    public getCacheObjectType = () => `${this.cacheObjectType}`;
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Generic data action that is just used for passing data around the app
 *
 * Note that this doesn't actually expose a data action, its just a base action
 * that others can use to create a data action with their own input
 */
export async function getGenericAction<T>(input: GenericInput<T>, ctx: IActionContext): Promise<T> {
    return input.result;
}
