import { GenericInput, getGenericAction } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction, IActionInput } from '@msdyn365-commerce/core';
import { IActiveTabState } from './tab-state';

export function createTabStateInput(result: IActiveTabState): GenericInput<IActiveTabState> {
    return new GenericInput<IActiveTabState>('activeTabState', result, 'IActiveTabState');
}

const createTabStateInputInternal = (): IActionInput => {
    return createTabStateInput({});
};

export default createObservableDataAction({
    action: <IAction<IActiveTabState>>getGenericAction,
    input: createTabStateInputInternal
});
