import { IAny, ICreateActionContext, IGeneric, IRequestContext } from '@msdyn365-commerce/core';
import { QueryResultSettings, SortColumn } from '@msdyn365-commerce/retail-proxy';
import { IQueryResultSettingsModuleConfig } from './IQueryResultSettingsModuleConfig';

/**
 * Wrapper for query result settings, which guarantees some properties that are optional in the real subject
 * and includes some common utilities for data actions
 */
export class QueryResultSettingsProxy {
    // real subject
    private queryResultSettings: QueryResultSettings;

     get QueryResultSettings(): QueryResultSettings {
        return this.queryResultSettings;
    }

    /**
     * Gets the hints for the cache key based on query result settings
     */
    get cacheKeyHint(): string {
        // the factory method guarantees values are defined
        const topHint = `top-${this.queryResultSettings.Paging!.Top!}`;
        const skipHint = `skip-${this.queryResultSettings.Paging!.Skip!}`;
        const sortingHintData = (this.queryResultSettings.Sorting!.Columns || []).map((column: SortColumn) => {
            return `${column.ColumnName}--${column.IsDescending && 'desc' || 'asc'}`;
        }).join(',') || undefined;
        const sortingHint = `sortBy-${sortingHintData}`;
        return `${topHint}|${skipHint}|${sortingHint}`;
    }

    /**
     * Factory method for data actions
     * @param inputData input data
     */
    public static fromInputData(inputData: ICreateActionContext<IGeneric<IAny>>): QueryResultSettingsProxy {
        const { requestContext, config } = inputData;
        return QueryResultSettingsProxy.fromModuleData(requestContext, config as IQueryResultSettingsModuleConfig);
    }

    /**
     * Factory method for modules
     * @param requestContext requestContext
     * @param config module config from props
     */
    public static fromModuleData(requestContext: IRequestContext, config: IQueryResultSettingsModuleConfig): QueryResultSettingsProxy {
        const sortingCriteria = config && config.sortingCriteria || undefined;
        const query = requestContext && requestContext.query;
        const top = query && query.top || undefined;
        const skip = query && query.skip || undefined;

        const queryResultSettings = {
            Paging: {
                Skip: (skip && Number(skip) || 0),
                Top: (top && Number(top) || 50)
            },
            Sorting: sortingCriteria || {}
        };

        return new QueryResultSettingsProxy(queryResultSettings);
    }

    /**
     * Factory method
     */
    public static getDefault(): QueryResultSettingsProxy {
        return new QueryResultSettingsProxy({ Paging: {}, Sorting: {} });
    }

    /**
     * private constructor, expect instances to be created with the factory method
     */
    private constructor(
        queryResultSettings: QueryResultSettings
    ) {
        this.queryResultSettings = queryResultSettings;
    }
}