import { IAction } from '@msdyn365-commerce/core';
import { createObservableDataAction, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { IDataServiceRequest, retailAction } from '@msdyn365-commerce/retail-proxy/dist';
import { createSearchByTextInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';

/**
 * createInput method for the searchByText data action
 */
export function createSearchByTextInputFunc (inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): IDataServiceRequest {
    const { requestContext: {query, apiSettings: {channelId}}} = inputData;

    const search = query && query.q;

    if (!search) {
        throw new Error('Unable to preform search without search text. Query string ?q={searchText} must be present or should be configued in module data.');
    }

    const querySettingsProxy = QueryResultSettingsProxy.fromInputData(inputData);
    return createSearchByTextInput(querySettingsProxy.QueryResultSettings, Number(channelId), 0, search);
}

/**
 * The searchByText data action
 * Queries RetailServers product searchByText API using a
 * query string parameter and returns a list of ProductSearchResults
 */
export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/search-by-text',
    action: <IAction<ProductSearchResult[]>>retailAction,
    input: createSearchByTextInputFunc
});
