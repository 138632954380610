import { FullProduct, IRefineFullProductSearchOutput } from '@msdyn365-commerce/commerce-entities';
import { CacheType, IAction, IActionInput } from '@msdyn365-commerce/core';
import { createObservableDataAction, IActionContext, IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { ProductRefinerValue, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { refineSearchByCategoryAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import getFullProducts, { FullProductInput, ProductDetailsCriteria } from './get-full-products';
import { QueryResultSettingsProxy } from './utilities/QueryResultSettingsProxy';
import { getProductDetailsCriteriaFromActionInput } from './utilities/utils';

/**
 * Refine search for full products input
 */
export class FullProductsRefineSearchByCategoryInput implements IActionInput {
    public readonly categoryId?: number;
    public readonly channelId?: number;
    public readonly refinementCriteria: ProductRefinerValue[];
    public readonly catalogId: number;
    public ProductDetailsCriteria?: ProductDetailsCriteria;
    public readonly queryResultSettingsProxy: QueryResultSettingsProxy;

    constructor(
        queryResultSettingsProxy: QueryResultSettingsProxy,
        categoryId?: number,
        channelId?: number,
        refinementCriteria?: ProductRefinerValue[],
        catalogId?: number,
        criteria?: ProductDetailsCriteria

    ) {
        this.queryResultSettingsProxy = queryResultSettingsProxy;
        this.categoryId = categoryId;
        this.channelId = channelId;
        this.refinementCriteria = refinementCriteria || [];
        this.catalogId = catalogId || 0;
        this.ProductDetailsCriteria = criteria;
    }

    public getCacheKey = () => `FullProductsRefineSearchByCategoryInputCache`;
    public getCacheObjectType = () => 'FullProductsRefineSearchByCategoryInput';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Creates the input required to make the core action calls
 */
export const createFullProductsRefineSearchByCategoryInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const refinementCriteria = inputData.config && inputData.config.refinementCriteria;
    const queryResultSettingsProxy = QueryResultSettingsProxy.fromInputData(inputData);
    if (!Array.isArray(refinementCriteria)) {
        return new FullProductsRefineSearchByCategoryInput(queryResultSettingsProxy);
    }

    if (inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.categoryId) {
        const categoryId = Number(inputData.requestContext.query.categoryId);
        const channelId = inputData.requestContext.apiSettings.channelId;
        const catalogId = inputData.requestContext.apiSettings.catalogId;
        const productDetailsCriteria = getProductDetailsCriteriaFromActionInput(inputData);
        return new FullProductsRefineSearchByCategoryInput(
            queryResultSettingsProxy,
            categoryId,
            +channelId,
            refinementCriteria,
            catalogId,
            productDetailsCriteria
        );
    }

    throw new Error('Please specify categoryId, refinerId, and refinerSourceValue query string in request.');
};

/**
 * Calls the refine-search-by-category action.
 * Based on search result calls get-full-products to get all the product details.
 */
export async function getFullProductsByRefineSearchCategoryAction(
    input: FullProductsRefineSearchByCategoryInput,
    ctx: IActionContext
): Promise<IRefineFullProductSearchOutput> {
    if (!input || !input.refinementCriteria) {
        ctx.trace('[getFullProductsByRefineSearchCategoryAction] Invalid input.');
        return <IRefineFullProductSearchOutput>{};
    }

    const hasSortingColumn = input.queryResultSettingsProxy.QueryResultSettings &&
        input.queryResultSettingsProxy.QueryResultSettings.Sorting &&
        input.queryResultSettingsProxy.QueryResultSettings.Sorting.Columns &&
        input.queryResultSettingsProxy.QueryResultSettings.Sorting.Columns.length > 0;
    if (!input.refinementCriteria.length && !hasSortingColumn) {
        ctx.telemetry.trace('[getFullProductsByRefineSearchCategoryAction] No refiners or sorting specified.');
        return <IRefineFullProductSearchOutput>{};
    }
    const { apiSettings } = ctx.requestContext;

    let refinementCriteria: ProductRefinerValue[] = [];
    const fullProductInputs = await refineSearchByCategoryAsync(
        { callerContext: ctx, queryResultSettings:  input.queryResultSettingsProxy.QueryResultSettings },
        input.channelId || 0,
        input.catalogId,
        input.categoryId || 0,
        input.refinementCriteria
        ).then(searchResults => {
            refinementCriteria = input.refinementCriteria;
            return searchResults.map(
                (product: ProductSearchResult): FullProductInput => {
                    return new FullProductInput(product.RecordId, apiSettings, input.ProductDetailsCriteria || new ProductDetailsCriteria());
                }
            );
        });

    if (fullProductInputs.length > 0) {
        const productResult = await <Promise<FullProduct[]>>getFullProducts(fullProductInputs, ctx);
        return {
            productSearchResult: productResult,
            refinementCriteria: refinementCriteria
        };
    } else {
        return {
            productSearchResult: [],
            refinementCriteria: refinementCriteria
        };
    }
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-full-products-by-refine-search-category',
    action: <IAction<IRefineFullProductSearchOutput>>getFullProductsByRefineSearchCategoryAction,
    input: createFullProductsRefineSearchByCategoryInput,
    isBatched: false
});
