import { buildCacheKeyWithUrlTokens, CurrentCategoryInput, getCategoryUrl, getCurrentCategory } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    getFriendlyName,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext,
    IRequestContext
} from '@msdyn365-commerce/core';
import { IDefaultPageSummaryConfig } from '../default-page-summary/default-page-summary.props.autogenerated';
import { IPageSummaryData } from '../IPageSummaryData';

/** Category Page Summary Input */
export class CategoryPageSummaryInput implements IActionInput {
    public requestContext: IRequestContext;
    public config: IDefaultPageSummaryConfig;

    constructor(config: IDefaultPageSummaryConfig, requestContext: IRequestContext) {
        this.config = config || {};
        this.requestContext = requestContext;
    }

    public getCacheObjectType = (): string => 'CategoryPageSummary';
    public getCacheKey = (): string => buildCacheKeyWithUrlTokens('CategoryPageSummary', this.requestContext);
    public dataCacheType = (): CacheType => 'request';
}

const createGetCurrentCategoryInput = (inputData: IActionContext): CurrentCategoryInput => {
    if (inputData && inputData.requestContext) {
        return new CurrentCategoryInput(inputData.requestContext);
    }

    throw new Error('Please specify categoryId query string in request.');
};

const action = async (input: CategoryPageSummaryInput, context: IActionContext): Promise<IPageSummaryData> => {
    const { config } = input;
    let currentCategory;
    try {
        currentCategory = await getCurrentCategory(createGetCurrentCategoryInput(context), context);
    } catch (e) {
        // Do nothing, if the action fails fallback to values defined in data
    }
    if (currentCategory) {
        let categoryUrl: string | undefined;
        try {
            // reset category's name neutralizedName
            const localName = getFriendlyName(context.requestContext.locale, currentCategory.NameTranslations);
            const neutralizedName = currentCategory.NeutralizedName || currentCategory.Name;
            currentCategory.Name = localName;
            currentCategory.NeutralizedName = neutralizedName;
            categoryUrl = getCategoryUrl(currentCategory);
            // @ts-ignore - TODO: property exits in new version of SDK. Remove once released.
            const canonicalDomain = context.requestContext.canonicalDomain;
            if (categoryUrl && canonicalDomain) {
                categoryUrl = `https://${canonicalDomain}${categoryUrl}`;
            } else {
                categoryUrl = undefined;
            }
        } catch (e) {
            categoryUrl = undefined;
        }
        return {
            title: currentCategory.Name,
            sharingImageUrl: currentCategory.OfflineImage,
            canonicalUrl: categoryUrl,
            faviconUrl: config && config.faviconUrl
        };
        // If the action fails fallback to values defined from data
    } else if (config) {
        return {
            title: config.title,
            sharingImageUrl: config.sharingImage && config.sharingImage.src,
            faviconUrl: config && config.faviconUrl
        };
    } else {
        return {};
    }
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/page-summary/category-page-summary',
    action: <IAction<IPageSummaryData>>action,
    input: (args: ICreateActionContext) => {
        return new CategoryPageSummaryInput(<IDefaultPageSummaryConfig>args.config, args.requestContext);
    }
});
