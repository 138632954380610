import { CacheType, IAction, IActionContext, IActionInput, ICommerceApiSettings, msdyn365Commerce } from '@msdyn365-commerce/core';
import { createObservableDataAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import Cookie from 'js-cookie';

import { copyAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { buildCacheKey } from './utilities/utils';

import { getCartState } from '@msdyn365-commerce/global-state';

/**
 * Input class for getCheckoutCart data action
 */
export class GetCheckoutCartInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ActiveCheckoutCart`, this.apiSettings);
    public getCacheObjectType = () => 'ActiveCheckoutCart';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext) => {
    return new GetCheckoutCartInput(inputData.requestContext.apiSettings);
};

// @ts-ignore
const CartCookieName: string = msdyn365Commerce.CartCookie || 'cart';

/**
 * Data action to copy the cart for checkout actions
 */
export async function getCheckoutCart(input: GetCheckoutCartInput, ctx: IActionContext): Promise<Cart> {
    const cartId = Cookie.get(CartCookieName) || '';
    if (cartId) {
        const currentCartState = await getCartState(ctx);
        const activeCart = currentCartState.cart;

        // check if there is an active cart and it isn't empty
        if (activeCart && Object.keys(activeCart).length > 0) {
            const checkoutCart = await copyAsync({ callerContext: ctx }, activeCart.Id, 2);

            if (checkoutCart) {
                return Array.isArray(checkoutCart) ? checkoutCart[0] : checkoutCart;
            }
        } else {
            ctx.trace(`[get-checkout-cart] Can't create a checkout cart since active cart is empty`);
        }
    }

    return <Cart>{};
}

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/retail-actions/get-checkout-cart',
    action: <IAction<Cart>>getCheckoutCart,
    input: createInput
});
