import { IAny, ICreateActionContext, IGeneric } from '@msdyn365-commerce/core';
import { QueryResultSettings } from '@msdyn365-commerce/retail-proxy';
import { QueryResultSettingsProxy } from './QueryResultSettingsProxy';

export interface ISearchData {
    q: string;
    skip: number;
    top: number;
    itemsPerPage: string;
    maxItems: string;
    channelId: number;
    queryResultSettings: QueryResultSettings;
}

export const parseSearchData = (inputData: ICreateActionContext<IGeneric<IAny>>): ISearchData => {
    // query is of type 'IDictionary<string> | undefined', so that q and skip are not recognized by tsc
    // @ts-ignore:2339
    const { requestContext: {query: {q, top, skip}, apiSettings: {channelId}}, config: {maxItems, itemsPerPage}} = inputData;
    const queryResultSettings = QueryResultSettingsProxy.fromInputData(inputData).QueryResultSettings;
    return {
        q,
        skip: (skip && Number(skip) || 0),
        top: (top && Number(top) || 50),
        itemsPerPage, // TODO BUGBUG 21667361
        maxItems, // TODO BUGBUG 21667361
        channelId,
        queryResultSettings
    };
};