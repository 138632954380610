import msdyn365Commerce, { IParameterizeFeatureFlags } from '@msdyn365-commerce/core';

export function parameterize(name: string = '', sep = '-', parameterizeFeatureFlags:IParameterizeFeatureFlags = {}, neutralizedName?:string) {
    // if neutralizedName flag enabled, we use neutralizedName to construct string
    const candidateName = (msdyn365Commerce.platformSettings && msdyn365Commerce.platformSettings.enableUrlLocalization || parameterizeFeatureFlags.enableUrlLocalization)? name: neutralizedName || name;
    /*
    1	‘ ‘ (space)	-> (single dash)
    3	Any character disallowed in URLs -> URL-encode
    5	‘ & ‘ (space ampersand space) -> (single dash)
    6	‘ - ‘ (space dash space) -> (single dash)
    7	‘  ‘ (2+ spaces) -> (single dash)
    10	[A-Z] (capital letters) -> [a-z] (lower-case)
    12	Any non-ASCII character outside of the set of disallowed  characters -> URL-encode
    */
    if (parameterizeFeatureFlags.enableUrlEncoding || msdyn365Commerce.platformSettings && msdyn365Commerce.platformSettings.enableUrlEncoding) {
        // replace url reserved characters with dash, and remove front and end dash;
        // &*!() reserved characters that can not be encoded by encodeURIComponent()
        return encodeURIComponent(candidateName.toLowerCase().replace(/[\s&*!()']+/g, '-').trim().replace(/\-+/g, '-').replace(/^\-+|\-+$/g, ''));
    }
    return name
        .toLowerCase()
        .replace(/[^a-z0-9\-_]+/g, ' ')
        .trim()
        .replace(/\s+/g, sep);
}
