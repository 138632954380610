import { action, computed, observable } from 'mobx';

import { IActionContext } from '@msdyn365-commerce/core';
import { IStoreSelectionStateContext, IStoreSelectorLocation, IStoreSelectorStateManager } from './i-store-selection-state-manager';

/**
 * Abstract instance of IStoreSelectorStateManager with base implementation
 * of everything except the geoLocate function
 */
export abstract class BaseStoreSelectorStateManager implements IStoreSelectorStateManager {
    @computed public get isDialogOpen(): Readonly<boolean> {
        return this._context !== undefined;
    }

    @observable protected _context?: IStoreSelectionStateContext;
    @computed public get context(): Readonly<IStoreSelectionStateContext | undefined> {
        return this._context;
    }

    public abstract geoLocate(searchTerm: string, actionContext: IActionContext): Promise<IStoreSelectorLocation | undefined>;

    @action
    public async openDialog(input: IStoreSelectionStateContext): Promise<void> {
        if (this._context) {
            throw new Error('Dialog is already opened');
        }

        this._context = input;
    }

    @action
    public async closeDialog(): Promise<void> {
        this._context = undefined;
    }
}