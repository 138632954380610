import { Image, ISubList } from '@msdyn365-commerce-modules/data-types';
import { buildCacheKeyWithUrlTokens, getList, ListInput } from '@msdyn365-commerce-modules/retail-actions';
import { IProductItem } from '@msdyn365-commerce-modules/retail-actions/dist/types/get-list';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric,
    IRequestContext
} from '@msdyn365-commerce/core';
import { IDefaultPageSummaryConfig } from '../default-page-summary/default-page-summary.props.autogenerated';
import { IPageSummaryData } from '../IPageSummaryData';

/** List Page Summary Input */
export class ListPageSummaryInput implements IActionInput {
    public requestContext: IRequestContext;
    public config: IDefaultPageSummaryConfig;

    constructor(config: IDefaultPageSummaryConfig, requestContext: IRequestContext) {
        this.config = config || {};
        this.requestContext = requestContext;
    }

    public getCacheObjectType = (): string => 'ListPageSummary';
    public getCacheKey = (): string => buildCacheKeyWithUrlTokens('ListPageSummary', this.requestContext);
    public dataCacheType = (): CacheType => 'request';
}

/**
 * List item type enum
 */
const enum ListItemType {
    list = 'list',
    product = 'product'
}

/**
 * Get the url of sub list.
 */
function getSubListUrl(listName: string, sitePath: string): string {
    if (!listName) {
        return '';
    }

    if (!sitePath) {
        return `/${listName}.l`;
    }

    // sitePath has a leading '/'
    return `${sitePath}/${listName}.l`;
}

/**
 * Get sub list item.
 */
function getSubListItem(item: IGeneric<IAny>, sitePathOfRequest: string): ISubList {
    const imageList: Image[] = getImages(item.fields.content.images);

    const sublistHref = getSubListUrl(item.fields._name, sitePathOfRequest);
    return {
        Title: item.fields.content.title,
        Description: item.fields.content.description,
        ShortDescription: item.fields.content.shortDescription,
        BackgroundColor: item.fields.content.backgroundColor,
        ForegroundColor: item.fields.content.foregroundColor,
        Href: sublistHref,
        Images: imageList
    };
}

/**
 * Get site path
 */
function getSitePath(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): string {
    // @ts-ignore
    return inputData && inputData.requestContext && inputData.requestContext.sitePath ? inputData.requestContext.sitePath : '';
}

/**
 * Get items per page
 */
function getItemsPerPage(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): number {
    if (!inputData || !inputData.config || !inputData.config.itemsPerPage) {
        return 50;
    }

    const result = Number(inputData.config.itemsPerPage);
    if (isNaN(result)) {
        return 50;
    }

    return result;
}

/**
 * Get skip count
 */
function getSkipCount(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): number {
    return inputData && inputData.requestContext && inputData.requestContext.query && inputData.requestContext.query.skipCount
        ? Number(inputData.requestContext.query.skipCount)
        : 0;
}

/**
 * Get images.
 */
function getImages(images: IGeneric<IAny>[]): Image[] {
    const resultImageList: Image[] = [];

    if (!images) {
        return resultImageList;
    }

    images.forEach(item => {
        if (item && item.image && item.image.href) {
            // add image item
            const imageItem: Image = {
                href: item.image.href,
                altText: item.image.altText,
                title: item.image.title,
                width: item.image.width,
                height: item.image.height
            };

            resultImageList.push(imageItem);
        }
    });

    return resultImageList;
}

/**
 * Get input list data.
 */
function getInputListData(inputData: ICreateActionContext<IGeneric<IAny>, IGeneric<IAny>>): IGeneric<IAny> {
    let listData =
        !inputData || !inputData.requestContext || !inputData.requestContext.pageData ? null : inputData.requestContext.pageData.list;
    if (
        !inputData ||
        !inputData.requestContext ||
        !inputData.requestContext.pageData ||
        !inputData.requestContext.pageData.list ||
        !inputData.requestContext.pageData.list.content ||
        !inputData.requestContext.pageData.list.content.items ||
        !inputData.requestContext.pageData.list.content.title ||
        !inputData.requestContext.pageData.list._id
    ) {
        if (
            !inputData ||
            !inputData.data ||
            !inputData.data.list ||
            !inputData.data.list.content ||
            !inputData.data.list.content.items ||
            !inputData.data.list.content.title ||
            !inputData.data.list._id
        ) {
            console.error('data is not well defined for list action input');
            throw new Error('data is not well defined for list action input');
        }

        listData = inputData.data.list;
    }
    return listData;
}

const createGetListInput = (inputData: IActionContext): ListInput => {
    const listData: IGeneric<IAny> = getInputListData(inputData);
    const listItems: (IProductItem[] | ISubList)[] = [];
    let productList: IProductItem[] = [];

    const parentImageList: Image[] = getImages(listData.content.images);

    const itemsPerPage = getItemsPerPage(inputData);
    const skipCount = getSkipCount(inputData);

    // This is the list contains all product and will be used to call getSimpleProducts data action.
    const productItems: IProductItem[] = [];
    const sum = skipCount + itemsPerPage;
    for (let index = skipCount; index < listData.content.items.length && index < sum; index++) {
        const item = listData.content.items[index];
        if (item.type === ListItemType.list) {
            if (!item.fields || !item.fields.content || !item.fields.content.title) {
                console.error(`sublist item fields, content or title missing in list ${listData._id}`);
                continue;
            }

            // if any elements in product list, then copy it and add to list items
            if (!(productList.length === 0)) {
                const clonedList = [...productList];
                listItems.push(clonedList);
                productList = [];
            }

            // build and add sublist item
            listItems.push(getSubListItem(item, getSitePath(inputData)));
        }

        if (item.type === ListItemType.product) {
            if (!item.fields || !item.fields.recordId) {
                console.error(`product item missing recordId in list ${listData._id}`);
                continue;
            }

            // add product item
            const productItem: IProductItem = {
                RecordId: item.fields.recordId,
                CatalogId: item.fields.catalogId || '0'
            };

            productList.push(productItem);
            productItems.push(productItem);
        }
    }

    // save the last portion of product items.
    if (!(productList.length === 0)) {
        listItems.push(productList);
    }

    return new ListInput(
        listData._id,
        listData.content.title,
        listData.content.description,
        listData.content.shortDescription,
        listData.content.backgroundColor,
        listData.content.foregroundColor,
        parentImageList,
        listItems,
        productItems,
        listData.content.items.length,
        itemsPerPage,
        skipCount
    );
};

const action = async (input: ListPageSummaryInput, context: IActionContext): Promise<IPageSummaryData> => {
    const { config } = input;
    let listData;
    try {
        listData = await getList(createGetListInput(context), context);
    } catch (e) {
        // Do nothing, if there's an error running the action fall back to values defined from data
    }
    if (listData && listData.Title) {
        return {
            title: listData.Title,
            description: listData.Description
        };
        // If the action fails fallback to values defined from data
    } else if (config) {
        return {
            title: config.title,
            description: config.description
        };
    } else {
        return {};
    }
};

export default createObservableDataAction({
    id: '@msdyn365-commerce-modules/page-summary/list-page-summary',
    action: <IAction<IPageSummaryData>>action,
    input: (args: ICreateActionContext) => {
        return new ListPageSummaryInput(<IDefaultPageSummaryConfig>args.config, args.requestContext);
    }
});
